import React, { createContext, useContext, useState } from "react";

export interface ContextProps {
    isDemoFormOpenState: [
        boolean,
        React.Dispatch<React.SetStateAction<boolean>>
    ];
    clickedMenuState: [string, React.Dispatch<React.SetStateAction<string>>];
}

export const AppContext = createContext<ContextProps | undefined>(undefined);

export const useAppContext = () => useContext(AppContext) as ContextProps;

interface Props {
    children?: React.ReactNode;
}

const AppContextProvider = ({ children }: Props) => {
    // this state will be shared with all components
    const [isDemoFormOpen, setIsDemoFormOpen] = useState(false);
    const [clickedMenuValue, setClickedMenuValue] = useState("Home");

    return (
        // this is the provider providing state
        <AppContext.Provider
            value={{
                isDemoFormOpenState: [isDemoFormOpen, setIsDemoFormOpen],
                clickedMenuState: [clickedMenuValue, setClickedMenuValue],
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
