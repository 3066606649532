import { Container, Row, Image, Col } from "react-bootstrap";

function Mission({ missiontext }: any) {
    return (
        <div className="bgGreey" id="mission">
            {missiontext.map((item: any, i: any) => (
                <Container className="padding-70">
                    <h3 className="header" key={i}>
                        {item.title}
                    </h3>
                    <Col md={12} className="no-padding-mobile">
                        <Row>
                            <Col md={4}>
                                <Image
                                    className="w-100 rounded LeftImagePadding"
                                    src={item.image}
                                />
                            </Col>
                            <Col md={8}>
                                <div className="justify-content-center mgtop-13">
                                    <span className="aboutsubtitle">
                                        {item.span1}{" "}
                                    </span>
                                    <p className="aboutParagrph">
                                        {item.para1}
                                    </p>
                                    <span className="aboutsubtitle">
                                        {item.span2}{" "}
                                    </span>
                                    <p className="aboutParagrph">
                                        {item.para2}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            ))}
        </div>
    );
}
export default Mission;
