import { useEffect, useState } from "react";
import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';

function MentorErp({ mentorerp }: any) {

    return (
        <>
            <div className="bgBlue">
                {mentorerp.map((items: any, i: any) => (
                    <Container key={i} className='paddingBottom80'>
                        <h2 className="txtwhite">{items.head}</h2>
                        <Row className="boxMobile pr-lg-0">
                            {items.imageset.map((item: any, i: any) => (
                                <Col key={i} md={6}>
                                    <div className="bigBox hovergrey">
                                        <div className="erpcircle">
                                            <Image src={item.image} />
                                        </div>
                                        <div className="erpSubtitle">{item.title}</div>
                                        <p className="paragraph text-center">
                                            {item.para}
                                        </p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                ))}
            </div>
        </>
    )
}
export default MentorErp