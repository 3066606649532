import { Button, Container, Image, Nav, Row, Col } from "react-bootstrap";

function TDDmethod({ tddmethod }: any) {
    return (
        <div className="bgGreey paddingBottom100" id="qualitytdd">
            {tddmethod.map((items: any, i: any) => (
                <Container className="container">
                    <h2 className="txtblue paddingBottom30 mb-tab-10">
                        {items.title}
                    </h2>
                    {items.box1.map((item: any, i: any) => (
                        <Row className="row mx-0">
                            <Col md={5}>
                                <Image className="w-100" src={item.image} />
                            </Col>
                            <Col md={7}>
                                <h4 className="subtitle">{item.subtitle}</h4>
                                {item.paragraph.map((x: any, i: any) => (
                                    <p className="paragraph">{x.para}</p>
                                ))}
                            </Col>
                        </Row>
                    ))}
                    <hr className="linemargin" />
                    {items.box2.map((item: any, i: any) => (
                        <Row className="row">
                            <Col md={7}>
                                <h4 className="subtitle">{item.subtitle}</h4>
                                {item.paragraph.map((x: any, i: any) => (
                                    <p className="paragraph">{x.para}</p>
                                ))}
                            </Col>
                            <Col md={5}>
                                <Image className="w-100" src={item.image} />
                            </Col>
                        </Row>
                    ))}
                </Container>
            ))}
        </div>
    );
}
export default TDDmethod;
