import { useEffect, useLayoutEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Image,
    Modal,
    Nav,
    Navbar,
    Row,
} from "react-bootstrap";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../../assets/static/images/BloomLync-logo.png";
import demoImage from "../../assets/static/images/demoImage.jpg";
import { useAppContext } from "../../components/context";
import Demoform from "./demoform";

function Header(props: any) {
    const { pathname } = useLocation();
    const [bodyScroll, setBodyScroll] = useState(false);
    const [data, setData] = useState([]);
    const [closemenu, setClosemenu] = useState(false);
    const [showsubmenu, setShowsubmenu] = useState(false);
    const [showmainmenu, setShowmainmenu] = useState(false);
    const { isDemoFormOpenState, clickedMenuState } = useAppContext();
    const [isDemoFormOpen, setIsDemoFormOpen] = isDemoFormOpenState;
    const [clickedMenuValue, setClickedMenuValue] = clickedMenuState;

    useLayoutEffect(() => {
        window.addEventListener("scroll", () => {
            setBodyScroll(window.scrollY > 60);
        });
        fetch("/mockdata/menu.json")
            .then((res) => res.json())
            .then((res) => setData(res));
    }, []);

    const handleshowDemoModal = () => {
        setIsDemoFormOpen(true);
    };
    const handleCloseDemoModal = () => {
        setIsDemoFormOpen(false);
    };

    const handleClick = (label: any) => {
        setClickedMenuValue(label);
        if (label === "Services") {
            setShowsubmenu(!showsubmenu);
        } else {
            setShowmainmenu(false);
        }
        window.scrollTo(0, 0);
    };

    const handlesubMenuclick = () => {
        setShowsubmenu(!showsubmenu);
        setShowmainmenu(false);
    };

    const handleToggler = () => {
        setShowmainmenu(!showmainmenu);
    };

    const handlegoToHome = () => {
        setClickedMenuValue("Home");
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Navbar
                onToggle={() => handleToggler()}
                expanded
                className={`navbar flex-column navTop  ${
                    pathname === "/" ||
                    pathname === "/home" ||
                    (pathname === "/product" && window.innerWidth > 1024)
                        ? ""
                        : "txtBlack"
                } ${bodyScroll && window.innerWidth > 1024 ? "scrolled" : ""}`}
                expand="md"
                style={{ position: "fixed" }}
                bg="transparent"
                variant="light"
            >
                <Container>
                    <Navbar.Toggle></Navbar.Toggle>
                    <Navbar.Brand className="p-0">
                        <Nav.Link as={NavLink} to="/" className="p-0">
                            <Image
                                src={logo}
                                alt={logo}
                                className="logoheight"
                                onClick={handlegoToHome}
                            />
                        </Nav.Link>
                    </Navbar.Brand>
                    <div
                        className={
                            (showmainmenu || showsubmenu) &&
                            window.innerWidth < 767
                                ? "showmenu"
                                : "hidemenu pl-tab-45 pt-md-3 mb-lg-2"
                        }
                    >
                        <Navbar.Collapse id="navbarSupportedContent">
                            {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
                            {data.map((MenuItems: any, i: any) => (
                                <Nav key={i} className="ml-auto" as="ul">
                                    {MenuItems.menu.map(
                                        (items: any, i: any) => (
                                            <Nav.Item
                                                key={i}
                                                as="li"
                                                className={
                                                    items.subItems
                                                        ? "dropdown"
                                                        : ""
                                                }
                                            >
                                                <Nav.Link
                                                    onClick={() =>
                                                        handleClick(items.label)
                                                    }
                                                    as={NavLink}
                                                    to={items.link}
                                                    active={
                                                        clickedMenuValue ===
                                                        items.label
                                                    }
                                                    caseSensitive
                                                    key={i}
                                                    title={
                                                        items.label ===
                                                        "Products"
                                                            ? "Mentor ERP"
                                                            : ""
                                                    }
                                                >
                                                    {items.label}
                                                </Nav.Link>

                                                {items.subItems && (
                                                    <>
                                                        <i className="fa fa-angle-down"></i>
                                                        <Nav
                                                            key={i}
                                                            className={`dropdown-menu ${
                                                                showsubmenu &&
                                                                window.innerWidth <
                                                                    767
                                                                    ? "sub-menu-show"
                                                                    : "sub-menu"
                                                            } flex-column`}
                                                            as="ul"
                                                        >
                                                            {items?.subItems?.map(
                                                                (
                                                                    item: any,
                                                                    i: any
                                                                ) => (
                                                                    <Nav.Item
                                                                        as="li"
                                                                        key={i}
                                                                    >
                                                                        <HashLink
                                                                            to={
                                                                                item.link
                                                                            }
                                                                            className="p-0"
                                                                            smooth
                                                                            onClick={
                                                                                handlesubMenuclick
                                                                            }
                                                                        >
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </HashLink>
                                                                    </Nav.Item>
                                                                )
                                                            )}
                                                        </Nav>
                                                    </>
                                                )}
                                            </Nav.Item>
                                        )
                                    )}
                                </Nav>
                            ))}

                            <div className="demoBtn">
                                <NavLink to="#" onClick={handleshowDemoModal}>
                                    Schedule a Demo
                                </NavLink>
                            </div>
                        </Navbar.Collapse>
                    </div>
                </Container>
            </Navbar>
            <Modal
                centered
                show={isDemoFormOpen}
                onHide={handleCloseDemoModal}
                className="modalRounded"
                size="lg"
            >
                <Modal.Body className="p-0">
                    <Row>
                        <Col md="12" lg="7" className="modalRounded">
                            <Image
                                src={demoImage}
                                alt={demoImage}
                                className="modalImg"
                            />
                        </Col>
                        <Col md="12" lg="5" className="mobilewidth">
                            <Button
                                variant="default"
                                className="close modalClose"
                                onClick={handleCloseDemoModal}
                            >
                                &times;
                            </Button>

                            <Demoform onClose={handleCloseDemoModal} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Header;
