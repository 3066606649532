import { yupResolver } from "@hookform/resolvers/yup";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import reloadImage from "../../assets/static/images/reload.png";
import { mailSendingAPIUrl } from "../../shared/constant";

function Formdetails(props: any) {
    const [invalid, showInvalid] = useState(false);
    const [captchaText, setCaptchaText] = useState("");
    const [answer, setAnswer] = useState("");
    const [product, setProduct] = useState(false);
    const [service, setService] = useState(false);

    useEffect(() => {
        getRandomString();
    }, []);

    function getRandomString() {
        const token = nanoid(5);
        setAnswer(token);
        console.log(token);
    }

    const schema = yup.object().shape({
        institute: yup
            .string()
            .trim()
            .required("Institute name is required")
            .matches(
                /^[aA-zZ\s.]+$/,
                "Only alphabets are allowed for this field "
            ),
        name: yup
            .string()
            .trim()
            .min(3, "Name must be at least 3 characters")
            .required("Name is required")
            .matches(
                /^[aA-zZ\s.]+$/,
                "Only alphabets are allowed for this field "
            ),
        serviceType: yup.string().required("Select One Service Type"),
        service: yup
            .string()
            .required(product ? "Select One Product" : "Select One Service"),
        email: yup
            .string()
            .required("Email is required")
            .matches(
                /^\S+@\S+\.\S+$/,
                "Email should contain @ and . operators"
            ),
        comment: yup.string().nullable().required("Comment is required"),
        contact: yup
            .string()
            .required("Contact Number is required")
            .min(10, "Must be exactly 10 digits")
            .max(10, "Must be exactly 10 digits")
            .typeError("Contact Number is required"),
        captcha: yup.string().required("Please enter the Captcha"),
    });

    const form = React.useRef() as React.MutableRefObject<HTMLFormElement>;

    const [popup, setPopup] = useState(false);
    const handleClose = () => {
        setPopup(false);
    };

    const formInitialValues = {
        institute: "",
        email: "",
        name: "",
        service: "",
        serviceType: "",
        contact: "",
        comment: "",
        captcha: "",
    };

    const onSubmit = (data: any, e: any) => {
        e.preventDefault();
        if (data.captcha === answer && data !== null) {
            data.subject = "Contact Us";
            fetch(mailSendingAPIUrl.EMAIL_SENDING_API_URL, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-type": "application/json;charset=utf-8",
                },
            }).then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error);
                }
            );
            reset(formInitialValues, {
                keepValues: false,
            });
            setCaptchaText("");
            getRandomString();
            showInvalid(false);
            setPopup(true);
        } else {
            showInvalid(true);
        }
    };

    const handleSelect = (e: any) => {
        if (e === "Product") {
            setProduct(true);
            setService(false);
        } else if (e === "Service") {
            setService(true);
            setProduct(false);
        }
    };

    const handleCaptachaRefresh = () => {
        getRandomString();
        setCaptchaText("");
    };

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <div id="contactus">
            <Form
                onSubmit={handleSubmit(onSubmit)}
                className="contactForm"
                id="contactForm"
                ref={form}
            >
                <div className="header text-left">Contact us</div>

                <Form.Group>
                    <Form.Control
                        {...register("institute")}
                        placeholder="Name of Institute*"
                        className="input"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.institute?.message}
                    </span>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        {...register("name")}
                        placeholder="Your Name*"
                        type="text"
                        className="input"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.name?.message}
                    </span>
                </Form.Group>

                <select
                    {...register("serviceType", {
                        onChange: (e: any) => handleSelect(e.target.value),
                    })}
                    name="serviceType"
                    id="serviceType"
                    placeholder="Select Service Type *"
                    className="input"
                >
                    <option value="">Select Service Type *</option>
                    <option value="Product">Product</option>
                    <option value="Service">Service</option>
                </select>

                <div className="line-box">
                    <div className="line"></div>
                </div>
                <span style={{ color: "red" }}>
                    {errors?.serviceType?.message}
                </span>

                <select
                    {...register("service")}
                    name="service"
                    id="service"
                    placeholder={
                        product ? "Select Product*" : "Select Service*"
                    }
                    className="input"
                >
                    <option value="">
                        {product ? "Select Product*" : "Select Service*"}
                    </option>
                    {product && (
                        <>
                            <option value="Mentor ERP">Mentor ERP</option>
                        </>
                    )}

                    {service && (
                        <>
                            <option value="Custom Development">
                                Custom Development
                            </option>
                            <option value="Mobile App Development">
                                Mobile App Development
                            </option>
                            <option value="DevOps Implementation">
                                DevOps Implementation
                            </option>
                            <option value="Test Consulting">
                                Test Consulting
                            </option>
                            <option value="Test Automation">
                                Test Automation
                            </option>
                        </>
                    )}
                </select>

                <div className="line-box">
                    <div className="line"></div>
                </div>
                <span style={{ color: "red" }}>{errors?.service?.message}</span>

                <Form.Group>
                    <Form.Control
                        {...register("email")}
                        type="email"
                        placeholder="Email ID.*"
                        className="input"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.email?.message}
                    </span>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        {...register("contact")}
                        type="number"
                        placeholder="Contact No*"
                        className="input"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.contact?.message}
                    </span>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Comment*:</Form.Label>
                    <Form.Control
                        {...register("comment")}
                        name="comment"
                        className="form-control shadow-sm txtarea"
                        as="textarea"
                        rows={5}
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.comment?.message}
                    </span>
                </Form.Group>

                <Row>
                    <Col xs={12} md={5}>
                        <Form.Group>
                            <Form.Control
                                {...register("captcha")}
                                placeholder="Captcha*"
                                className="input"
                                autoComplete="off"
                                value={captchaText}
                                onChange={(e) => setCaptchaText(e.target.value)}
                            ></Form.Control>
                            <div className="line-box">
                                <div className="line"></div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={10} md={5}>
                        <Form.Group>
                            <Form.Control
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                placeholder="code"
                                className="input"
                                autoComplete="off"
                                type="text"
                                readOnly
                                name="mainCaptcha"
                                value={answer}
                            ></Form.Control>
                            <div className="line-box">
                                <div className="line"></div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={2} md={2}>
                        <Image
                            src={reloadImage}
                            className="reload"
                            onClick={handleCaptachaRefresh}
                        />
                    </Col>
                </Row>
                {!invalid && captchaText === "" && (
                    <span style={{ color: "red" }}>
                        {errors?.captcha?.message}
                    </span>
                )}
                {invalid && (
                    <Row>
                        <Form.Group>
                            <span style={{ color: "red" }}>
                                Invalid Captcha!...
                            </span>
                        </Form.Group>
                    </Row>
                )}
                <Col md={12} className="col-md-12 text-center mgtop-25">
                    <button
                        type="submit"
                        className="loadingBtn btn btn-lg custombtn btn-block txtwhite"
                        onMouseDown={handleSubmit(onSubmit)}
                    >
                        Submit
                    </button>
                </Col>
            </Form>

            <Modal show={popup} onHide={handleClose} className="alertBox">
                <Modal.Header
                    style={{ backgroundColor: "#28a745", color: "white" }}
                >
                    Thank you, your request has been sent.
                    <Button
                        variant="default"
                        className="close modalClose"
                        onClick={handleClose}
                    >
                        &times;
                    </Button>
                </Modal.Header>
            </Modal>
        </div>
    );
}
export default Formdetails;
