import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';
import Servicecardstandard from './servicecardstandard';
import Servicecardprofessional from './servicecardprofessional';
import Servicecardpremium from './servicecardpremium';

function Servicemodel({ service }: any) {
	return (
		<div className="bgWhite">
			{service.map((item: any, i: any) => (
				<Container key={i} className="container_padding">
					<h2 className="txtblue drivePadding mb-0">{item.head}</h2>
					<div className="drive">{item.subhead}</div>
					<Row>
						<Servicecardstandard lists1={item.list1} />
						<Servicecardprofessional lists2={item.list2} />
						<Servicecardpremium lists3={item.list3} />
					</Row>
				</Container>
			))}
		</div>
	);
}
export default Servicemodel;
