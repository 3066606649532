import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SubPageBanner2 } from "../service/bannerservice";
import Devops from "./devops";
import Primaryfocus from "./primary focus";
import Developmentservice from "../service/developmentservice";
import TDDmethod from "./TDD";

import Automation from "./automation";
import Testingservice from "./testservice";
import Testautomation from "./testautomation";
import { useAppContext } from "../context";

function Service() {
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const { clickedMenuState } = useAppContext();
    const [clickedMenuValue, setClickedMenuValue] = clickedMenuState;

    useEffect(() => {
        fetch("/mockdata/service.json")
            .then((res) => res.json())
            .then((res) => setData(res));

        setClickedMenuValue("Services");
    }, []);

    useEffect(() => {
        fetch("/mockdata/home.json")
            .then((res) => res.json())
            .then((res) => setData1(res));
    }, []);

    return (
        <>
            {data.map((item: any, i: any) => (
                <section key={i}>
                    <div>
                        <SubPageBanner2 Slider={item.subBanner} />
                        <Primaryfocus primaryfocus={item.primaryfocus} />
                        <Devops devops={item.devops} />
                        {data1.map((item: any, i: any) => (
                            <Developmentservice
                                development={item.development}
                            />
                        ))}

                        <TDDmethod tddmethod={item.tddmethod} />
                    </div>
                </section>
            ))}
        </>
    );
}

export default Service;
