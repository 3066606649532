import { useEffect, useState } from "react";
import { SubPageBanner } from "../../helpers/banner";
import Benefits from "./benefits";
import Channelpartner from "./channel";
import Contactinfo from "./contactinfo";
import Networkpartner from "./networkpartner";
import Technology from "./technology";
import { useAppContext } from "../context";

function Contact() {
    const [data, setData] = useState([]);
    const { clickedMenuState } = useAppContext();
    const [clickedMenuValue, setClickedMenuValue] = clickedMenuState;

    useEffect(() => {
        fetch("/mockdata/contact.json")
            .then((res) => res.json())
            .then((res) => setData(res));
    }, []);

    useEffect(() => {
        setClickedMenuValue("");
    });

    return (
        <>
            {data.map((item: any, i: any) => (
                <section key={i}>
                    <SubPageBanner Slider={item.subBanner} />
                    <Channelpartner channel={item.channel} />
                    <Benefits benefits={item.benefits} />
                    <Networkpartner networkpartner={item.networkpartner} />
                    <Technology technology={item.technology} />
                    <Contactinfo contactinfo={item.contactinfo} />
                </section>
            ))}
        </>
    );
}

export default Contact;
