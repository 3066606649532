import { Button, Container, Image, Nav, Row, Col, Card } from "react-bootstrap";

function Networkpartner({ networkpartner }: any) {
    return (
        <div className="bgWhite">
            {networkpartner.map((item: any, i: any) => (
                <Container key={i} className="padding-70 paddingBottom40">
                    <h3 className="header">{item.head}</h3>
                    <Col md={12} className="no-padding-mobile ">
                        <Row className="row">
                            {item.cards.map((x: any, i: any) => (
                                <Col
                                    key={i}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="customTowColumn"
                                >
                                    <Card className="border-0 mb-5 ">
                                        <Card.Img
                                            className="w-100"
                                            src={x.image}
                                            alt="..."
                                        />
                                        <Card.Body className="rounded-bottom-10 bgBlue">
                                            <Card.Title className="text-center text-white">
                                                <h4>{x.title}</h4>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Container>
            ))}
        </div>
    );
}
export default Networkpartner;
