import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';

function Benefits({ benefits }: any) {
	return (
		<div className="bgGreey">
			{benefits.map((item: any, i: any) => (
				<Container className="paddingBottom40 mobilePadding-0">
					<h2 key={i} className="txtblue paddingBottom40">
						{item.title}
					</h2>
					<Col md={12}>
						<Row className="row paddingBottom40">
							<Col
								md={5}
								sm={12}
								xs={12}
								className="mobilePadding-0"
							>
								<Image
									className="mobileImg"
									src={item.image}
									fluid
								/>
							</Col>
							<Col
								md={7}
								sm={12}
								xs={12}
								className="verticalAlign"
							>
								{/* <span className="subtxt">Benefits:</span>  */}

								<ul className="ul bulletfontsize">
									{item.lists.map((data: any, i: any) => (
										<li key={i}>{data.list}</li>
									))}
								</ul>
							</Col>
						</Row>
					</Col>
				</Container>
			))}
		</div>
	);
}
export default Benefits;
