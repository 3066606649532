import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';

function Testingservice({ testservice }: any) {

    return (
        <div className="bgWhite">

            <Container>
                {testservice.map((item: any, i: any) => (
                    <>
                        <h2 className="txtblue paddingBottom30">{item.title}</h2>

                        <Col md={12}>

                            <Row>
                                <Col md={4} >
                                    <Image className="LeftImage" src={item.image} />
                                </Col>
                                <Col md={8}>
                                    <p className="paragraph">{item.para1}</p>
                                    <p className="paragraph">{item.para2}</p>
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                {item.box1.map((a: any, i: any) => (
                                    <Col md={12}>
                                        <p className="fullparagrap">
                                            {a.para}
                                        </p>

                                    </Col>
                                ))}
                            </Row>


                        </Col>
                    </>
                ))
                }

            </Container>

        </div >
    )
}
export default Testingservice
