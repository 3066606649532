import { Col, Container, Image, Nav, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import locationImage from "../../assets/static/images/location.png";
import { HashLink, HashLink as Link } from "react-router-hash-link";
import { useEffect, useState } from "react";
import { useAppContext } from "../../components/context";

function Footer() {
    const [data, setData] = useState([]);
    const { clickedMenuState } = useAppContext();
    const [clickedMenuValue, setClickedMenuValue] = clickedMenuState;

    useEffect(() => {
        fetch("/mockdata/menu.json")
            .then((res) => res.json())
            .then((res) => setData(res));
    }, []);

    const handleClick = (link: string) => {
        let page = link.includes("/development") ? "Services" : "";
        setClickedMenuValue(page);
        if (!link.includes("#")) {
            window.scrollTo(0, 0);
        }
    };

    return (
        <footer className="footer mt-auto">
            <Container className=" bottom_border footermargin">
                <Row className="mt-sm-5">
                    <Col sm="4">
                        <h5 className="headin5_amrc col_white_amrc pt2 font18">
                            <Image
                                className="footerHeaderImg"
                                src={locationImage}
                            />{" "}
                            Corporate Office
                        </h5>
                        <ul className="footer_ul_amrc">
                            <li>No. 26/1, 1st Floor,</li>
                            <li>West Mada Street,</li>
                            <li>Maduravoyal, Chennai,</li>
                            <li>TN - 600095, India.</li>
                        </ul>
                    </Col>
                    <Col sm="4">
                        <h5 className="headin5_amrc col_white_amrc pt2 font18">
                            <Image
                                className="footerHeaderImg"
                                src={locationImage}
                            />{" "}
                            Madurai Office
                        </h5>
                        <ul className="footer_ul_amrc">
                            <li>No. 115/025/11275,</li>
                            <li>New Natham Road,</li>
                            <li>Thirupalai, Madurai,</li>
                            <li>TN - 625014, India.</li>
                        </ul>
                    </Col>
                    <Col sm="4">
                        <h5 className="headin5_amrc col_white_amrc pt2 font18">
                            <Image
                                className="footerHeaderImg"
                                src={locationImage}
                            />{" "}
                            EMEA Office
                        </h5>
                        <ul className="footer_ul_amrc">
                            <li>Troy Consultancy,</li>
                            <li>UNIT 65, Business Park,</li>
                            <li>Ardwick, Manchester M12 4AH,</li>
                            <li>United Kingdom.</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                        <Row>
                            <Col sm="4" className="padding-0 d-none d-sm-block">
                                <div className="follows"> Follows us at</div>
                                <ul className="list-unstyled list-inline social">
                                    <li className="list-inline-item">
                                        <Nav.Link
                                            href="https://www.facebook.com/bloomlync"
                                            target="_blank"
                                        >
                                            <i className="fa fa-facebook"></i>
                                        </Nav.Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Nav.Link
                                            href="https://www.linkedin.com/company/bloomlync"
                                            target="_blank"
                                        >
                                            <i className="fa fa-linkedin linkedinIcon"></i>
                                        </Nav.Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Nav.Link
                                            href="https://twitter.com/bloomlync"
                                            target="_blank"
                                        >
                                            <i className="fa fa-twitter twiterIcon"></i>
                                        </Nav.Link>
                                    </li>
                                </ul>
                            </Col>

                            <Col xs={6} sm="4" className="padding-0">
                                <ul className="list-unstyled pagesLink">
                                    {data.map((x: any) => (
                                        <Nav.Item as="li">
                                            {x.aboutMenu.map((item: any) => (
                                                <li className="pagesLink li">
                                                    <HashLink
                                                        to={item.link}
                                                        smooth
                                                        onClick={() =>
                                                            handleClick(
                                                                item.link
                                                            )
                                                        }
                                                    >
                                                        {item.label}
                                                    </HashLink>
                                                </li>
                                            ))}
                                        </Nav.Item>
                                    ))}
                                </ul>
                            </Col>
                            <Col xs={6} sm="4" className="padding-0">
                                <ul className="list-unstyled pagesLink">
                                    {data.map((x: any) => (
                                        <Nav.Item as="li">
                                            {x.mentorerplist.map(
                                                (item: any) => (
                                                    <li className="pagesLink li">
                                                        <HashLink
                                                            to={item.link}
                                                            smooth
                                                            onClick={() =>
                                                                handleClick(
                                                                    item.link
                                                                )
                                                            }
                                                        >
                                                            {item.label}
                                                        </HashLink>
                                                    </li>
                                                )
                                            )}
                                        </Nav.Item>
                                    ))}
                                </ul>
                            </Col>

                            <Col
                                xs={12}
                                sm="4"
                                className="col012 adding-0 d-block d-sm-none"
                            >
                                <div className="follows"> Follows us at</div>
                                <ul className="list-unstyled list-inline social">
                                    <li className="list-inline-item">
                                        <Nav.Link
                                            href="https://www.facebook.com/bloomlync"
                                            target="_blank"
                                        >
                                            <i className="fa fa-facebook"></i>
                                        </Nav.Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Nav.Link
                                            href="https://www.linkedin.com/company/bloomlync"
                                            target="_blank"
                                        >
                                            <i className="fa fa-linkedin linkedinIcon"></i>
                                        </Nav.Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Nav.Link
                                            href="https://twitter.com/bloomlync"
                                            target="_blank"
                                        >
                                            <i className="fa fa-twitter twiterIcon"></i>
                                        </Nav.Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                    {/* <hr> */}
                </Row>
            </Container>
            <div className="col-xs-12 col-sm-12 col-md-12 padding-0">
                <ul className="list-unstyled list-inline bottomtext">
                    <li className="list-inline-item privacy">
                        <Link to="/privacy#privacy" smooth>
                            Privacy Policy
                        </Link>
                    </li>
                    <li className="list-inline-item">
                        @2023 Bloomlync Technology Pvt Ltd.{" "}
                        <span className="reserved">All Rights Reserved</span>
                    </li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;
