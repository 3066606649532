import { Container, Image } from 'react-bootstrap';

function Keybenefits({ keybene }: any) {

    return (
        <>

            <div className="bgGreey">
                {keybene.map((items: any, i: any) => (
                    <Container key={i} className="paddingBottom40">

                        <h2 className="txtblue paddingBottom30">{items.title}</h2>
                        <div className=" mobile-scroll-img">
                            <Image className="mgbottom-100" src={items.image} />
                        </div>

                    </Container>
                ))}
            </div>

        </>
    )
}

export default Keybenefits