import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';

function Primaryfocus({ primaryfocus }: any) {

    return (
        <div>
            <div className="bgWhite">
                {primaryfocus.map((item: any, i: any) => (
                    <Container key={i} className="padding-100">
                        {/* <!-- <h2 className="txtblue paddingBottom30">Heading Here</h2> --> */}
                        <div className="col-md-12">
                            <Row className="row">
                                <Col md={5}>
                                    <Image className="LeftImage LeftImgHeight" src={item.image} />
                                </Col>
                                <Col md={7}>
                                    <p className="paragraph">{item.para}</p>
                                    <ul className="ul">
                                        {item.lists.map((x: any, i: any) => (
                                            <li key={i}>{x.list} </li>
                                        ))}
                                    </ul>
                                    <p className="paragraph">{item.para2}</p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                ))}
            </div>
        </div>
    )
}
export default Primaryfocus