import { Container, Row, Image, Col } from "react-bootstrap"

function Ourculture({ culture }: any) {
    return (
        <div className="bgWhite">
            {culture.map((item: any, i: any) => (
                <Container key={i} className="padding-70 padding-15-tablet"  id="culture">
                    <h3 className="header" id="culture">{item.title}</h3>
                    <p className="txtCenter">
                        {item.para}
                    </p>

                    <Container className="customWidth">
                        <>
                            <Row >
                                <Col md={6} className="no-padding-mobile ">
                                    <div className="aboutbox">
                                        <Image className="w-100" src={item.image1} />
                                        <div className="culturetitle">{item.title1}</div>
                                        <p className="culturBoxContent">
                                            {item.text1}
                                        </p>
                                    </div>

                                </Col>
                                <Col md={6} className="no-padding-mobile ">
                                    <div className="aboutbox">
                                        <Image className="w-100" src={item.image2} />
                                        <div className="culturetitle">{item.title2}</div>
                                        <p className="culturBoxContent">
                                            {item.text2}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </>

                        <Row>
                            <Col md={6} className="no-padding-mobile ">
                                <div className="aboutbox">
                                    <Image className="w-100" src={item.image3} />
                                    <div className="culturetitle">{item.title3}</div>
                                    <p className="culturBoxContent">
                                        {item.text3}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} className="no-padding-mobile ">
                                <div className="aboutbox">
                                    <Image className="w-100" src={item.image4} />
                                    <div className="culturetitle">{item.title4}</div>
                                    <p className="culturBoxContent">
                                        {item.text4}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="no-padding-mobile ">
                                <div className="aboutbox">
                                    <Image className="w-100" src={item.image5} />
                                    <div className="culturetitle">{item.title5}</div>
                                    <p className="culturBoxContent">
                                        {item.text5}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} className="no-padding-mobile ">
                                <div className="aboutbox">
                                    <Image className="w-100" src={item.image6} />
                                    <div className="culturetitle">{item.title6}</div>
                                    <p className="culturBoxContent">
                                        {item.text6}
                                    </p>
                                </div>
                            </Col>
                        </Row>

                    </Container >

                </Container >
            ))}
        </div>
    )
}
export default Ourculture