import { Col, Image, Row } from "react-bootstrap";

function EmeaInternational({ contactinfo }: any) {
    return (
        <div>
            {contactinfo.map((item: any, i: any) => (
                <>
                    {item.addrinternational.map((data: any, i: any) => (
                        <>
                            <h2 className="header addressTitle text-left">
                                {data.head}
                            </h2>
                            {data.emea.map((x: any, i: any) => (
                                <>
                                    <div key={i} className="addressSubtitle">
                                        {x.subhead}
                                    </div>
                                    <div className="address">
                                        <Row className="row">
                                            <Col
                                                md={1}
                                                sm={1}
                                                className="width10 width-mobile-45px"
                                            >
                                                <Image
                                                    className="locationimg"
                                                    src={x.image}
                                                />
                                            </Col>
                                            <Col
                                                md={11}
                                                sm={11}
                                                className="width-90 width-mobile-cal-45"
                                            >
                                                <ul>
                                                    {x.address.map(
                                                        (a: any, i: any) => (
                                                            <li key={i}>
                                                                {a.addr}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            ))}
                        </>
                    ))}
                    <br />
                    <hr />
                </>
            ))}
        </div>
    );
}

export default EmeaInternational;
