import { Button, Container, Image, Nav, Row, Col, Card } from 'react-bootstrap';

function Indiaaddr({ contactinfo }: any) {
    return (
        <div>
            {contactinfo.map((item: any, i: any) => (
                <>
                    {item.addrindia.map((x: any, i: any) => (
                        <>
                            <h2 key={i} className="header addressTitle text-left">
                                {x.head}
                            </h2>
                            <div className="addressSubtitle">
                                {x.subhead}
                            </div>

                            <div className="address">
                                <Row className="row">
                                    <Col md={1} sm={1} className="width10 width-mobile-45px">
                                        <Image className="locationimg" src={x.image} />
                                    </Col>
                                    <Col md={11} sm={11} className="width-90 width-mobile-cal-45">
                                        <ul>
                                            {x.address.map((data: any, i: any) => (
                                                <li key={i}>
                                                    {data.addr}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>
                            </div>

                        </>
                    ))}
                    <hr></hr>
                </>
            ))}
        </div>
    )
}
export default Indiaaddr