import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';

function Devops({ devops }: any) {
	return (
		<div id="devops">
			<Col md={12} className="no-padding">
				{devops.map((item: any, i: any) => (
					<div>
						<div className="process d-sm-block">
							<h2>{item.title}</h2>
							<p>{item.text}</p>
						</div>

						{/* <Image className="d-none d-sm-block w-100 processImgHeight" src={item.image} alt="First slide" />
                <Image className="d-block d-sm-none w-100 processImgHeight" src={item.image1} alt="First slide" /> */}
						<Image
							className="d-none d-sm-block w-100 processImgHeight"
							src={item.image}
							alt={item.image}
						/>
						<Image
							className="d-block d-sm-none w-100 processImgHeight"
							src={item.image1}
							alt={item.image1}
						/>
					</div>
				))}
			</Col>
		</div>
	);
}
export default Devops;
