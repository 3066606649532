import { Button, Container, Image, Nav, Row } from 'react-bootstrap';

function Testing({ testing }: any) {

  return (
    <>
      <div className="bgWhite">
        <Container className="paddingBottom60">
          {testing.map((items: any, i: any) => (
            <div key={i}  id="testpractise">
              <h2 className="txtblue paddingBottom30 ">{items.title}</h2>
              <Image className="w-75 center w-100-mobile" src={items.image} />
            </div>
          ))}
        </Container>
      </div>
    </>
  )
}
export default Testing