import { useEffect, useState } from "react";
import { SliderComponent } from "../../helpers/carousel";
import Development from "./development";
import MentorErp from "./mentorerp";
import FocusComponent from "./focustext";
import Testing from "./testing";
import Erpkey from "../../helpers/keycarousel";
import Freetrialform from "./freetrialform";

function Home() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch("/mockdata/home.json")
            .then((res) => res.json())
            .then((res) => setData(res));
    }, []);

    return (
        <>
            {data.map((item: any, i: any) => (
                <section key={i}>
                    <SliderComponent Slider={item.Slider} />
                    <FocusComponent Focus={item.Focus} />
                    <Development development={item.development} />
                    <Testing testing={item.testing} />
                    {/* <MentorErp mentorerp={item.mentorerp} /> */}
                    <Erpkey erpkey={item.erpkey} />
                </section>
            ))}
        </>
    );
}

export default Home;
