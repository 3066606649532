import React from "react";
import { Container, Image, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

const Privacy = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        fetch("/mockdata/privacy.json")
            .then((res) => res.json())
            .then((res) => setData(res));
    }, []);

    return (
        <Container
            className="col-md-12 no-padding banner-privacy"
            id="bannerdiv"
        >
            {data.map((x: any) => (
                <>
                    {x.privacyBanner.map((y: any) => (
                        <>
                            <Image
                                className="d-block w-100 fixed-height"
                                src={y.image}
                            />
                            {/* <div className="bannerdiv"> */}
                            {/* <h5>We create an equal culture environment</h5> */}
                            {/* <p>Where employees are valued, motivate each other to reach maximum potential </p>  */}
                            {/* </div> */}
                        </>
                    ))}
                </>
            ))}

            {data.map((item: any) => (
                <>
                    {item.privacytext.map((x: any) => (
                        <div className="bgGreey" id="privacy">
                            <Container className="container padding-70">
                                <h3 className="header">{x.title}</h3>
                                <Col className="col-md-12">
                                    <Row className="row">
                                        <Col className="col-md-12">
                                            <div className="justify-content-center mgtop-13">
                                                <p className="privacyparagraph">
                                                    {x.para}
                                                    <a href="mailto:helpdesk@bloomlync.com">
                                                        helpdesk@bloomlync.com
                                                    </a>
                                                    . {x.para1}
                                                </p>

                                                <span className="privacysubtitle">
                                                    {x.subtitle1}
                                                </span>
                                                {x.paras1.map((text: any) => (
                                                    <p className="privacyparagraph">
                                                        {text.para}
                                                    </p>
                                                ))}

                                                <span className="privacysubtitle">
                                                    {x.subtitle2}
                                                </span>
                                                <p className="privacyparagraph">
                                                    {x.para5}
                                                </p>
                                                <span className="privacysubtitle">
                                                    {x.subtitle3}
                                                </span>
                                                {x.paras2.map((text: any) => (
                                                    <p className="privacyparagraph">
                                                        {text.para}
                                                    </p>
                                                ))}
                                                <span className="privacysubtitle">
                                                    {x.subtitle4}
                                                </span>
                                                <p className="privacyparagraph">
                                                    {x.para8}
                                                </p>
                                                <span className="privacysubtitle">
                                                    {x.subtitle5}
                                                </span>
                                                <p className="privacyparagraph">
                                                    {x.para9}
                                                </p>
                                                <span className="privacysubtitle">
                                                    {x.subtitle6}
                                                </span>
                                                <p className="privacyparagraph">
                                                    {x.para10}
                                                    If you have any questions or
                                                    concerns at any time about
                                                    our privacy policy or the
                                                    use of your personal
                                                    information, please contact
                                                    us at{" "}
                                                    <a href="mailto:helpdesk@bloomlync.com">
                                                        helpdesk@bloomlync.com
                                                    </a>{" "}
                                                    and we will respond within
                                                    48 hours.
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Container>
                        </div>
                    ))}
                </>
            ))}
        </Container>
    );
};

export default Privacy;
