import { Container, Row, Image, Col } from "react-bootstrap"

function Empowerment({ empower }: any) {
    return (
        <div className="bgWhite" >
            {empower.map((items: any, i: any) => (
                <Container key={i} className="padding-100 padding-50-tablet" id="empower">
                    <Col md={12}>
                        <Row className="vertical-divider">
                            <Col md={4}>
                                <Image className="LeftImage w-85" src={items.image} />
                                <p className="italic-text">{items.para1}<span className="font-weight-bold ">{items.span}</span></p>
                                <p className="directorName">- <i className="italic-text">{items.para2}</i></p>
                            </Col>
                            <Col md={8} className="paddingLeft">
                                {items.paragraph.map((data: any, i: any) => (
                                    <p className="paragraph">{data.para}</p>

                                ))}
                            </Col>
                        </Row>
                    </Col>
                </Container>
            ))}
        </div>
    )
}
export default Empowerment