import { Button, Image } from "react-bootstrap";
// import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link";

const SubPageBanner2 = ({ Slider }: any) => {
    return (
        <>
            {Slider ? (
                <div id="bannerdiv">
                    <>
                        {Slider.map((item: any, i: any) => (
                            <div key={i}>
                                <Image
                                    src={item.image}
                                    className="d-none d-md-block w-100 fixed-height"
                                />
                                <Image
                                    src={item.mobileImage}
                                    className="d-block d-md-none w-100"
                                />
                                <div className="bannerdiv">
                                    <h5>{item.title}</h5>
                                    {item.content && <p>{item.content}</p>}
                                    <HashLink to="/contact#contactinfo" smooth>
                                        <Button className="btn txtwhite custombtn mobile-bottom mRight-25 ipadbtncss">
                                            {item.text}
                                        </Button>
                                    </HashLink>
                                </div>
                            </div>
                        ))}
                    </>
                </div>
            ) : null}
        </>
    );
};

export { SubPageBanner2 };
