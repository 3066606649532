import { Button, Container, Image, Nav, Row, Col } from "react-bootstrap";

function Testautomation({ testautomation }: any) {
    return (
        <div className="bgGreey" id="automation">
            {testautomation.map((items: any, i: any) => (
                <Container className="paddingBottom50">
                    <h2 className="txtblue paddingBottom30" id="automation">
                        {items.title}
                    </h2>
                    <Row className="mx-0">
                        {items.box.map((item: any, i: any) => (
                            <Col md={3} className="customTowColumn">
                                <div className="box boxPadding">
                                    <Col md={12}>
                                        <Image
                                            src={item.image}
                                            // className="testAutoImg"
                                            fluid
                                        />
                                    </Col>
                                    <span className="testingHeading">
                                        {item.subtitle}
                                    </span>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            ))}
        </div>
    );
}
export default Testautomation;
