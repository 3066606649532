import { Container, Row, Image, Col } from 'react-bootstrap';

function Coreteam({ core }: any) {
	return (
		<div className="bgGreey">
			{core.map((items: any, i: any) => (
				<Container key={i} className="padding-70" id="coreteam">
					<h3 className="header" id="team">
						{items.title}
					</h3>
					<Container className="customWidth container_padding">
						<Row className="vertical-divider profileBottom">
							<Col md={6}>
								<div className="profile">
									<Image
										className="circleImg mx-auto d-block"
										src={items.image}
									/>
									<div className="profileName">
										{items.name}
									</div>
									<div className="degree">{items.degree}</div>
									<p className="profileContent">
										{items.para}
									</p>
								</div>
							</Col>
							<Col md={6}>
								<div className="profile">
									<Image
										className="circleImg mx-auto d-block"
										src={items.image2}
									/>
									<div className="profileName">
										{items.name2}
									</div>
									<div className="degree">
										{items.degree2}
									</div>
									<p className="profileContent">
										{items.para2}
									</p>
								</div>
							</Col>
						</Row>
						<Row className="vertical-divider profileBottom">
							<Col md={6}>
								<div className="profile">
									<Image
										className="circleImg mx-auto d-block"
										src={items.image3}
									/>
									<div className="profileName">
										{items.name3}
									</div>
									<div className="degree">
										{items.degree3}
									</div>
									<p className="profileContent">
										{items.para3}
									</p>
								</div>
							</Col>
							<Col md={6}>
								<div className="profile">
									<Image
										className="circleImg mx-auto d-block"
										src={items.image4}
									/>
									<div className="profileName">
										{items.name4}
									</div>
									<div className="degree">
										{items.degree4}
									</div>
									<p className="profileContent">
										{items.para4}
									</p>
								</div>
							</Col>
						</Row>
						<Row className="vertical-divider profileBottom50 justify-content-center">
							<Col md={6}>
								<div className="profile">
									<Image
										className="circleImg mx-auto d-block"
										src={items.image5}
									/>
									<div className="profileName">
										{items.name5}
									</div>
									<div className="degree">
										{items.degree5}
									</div>
									<p className="profileContent">
										{items.para5}
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</Container>
			))}
		</div>
	);
}
export default Coreteam;
