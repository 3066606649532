import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';

function Cardlist({ listbox }: any) {
    return (
        <Col className="col-md-12 border-radius border efficencyBox mgbottom50 borderColor">
            {listbox.map((data: any, i: any) => (
                <Row className="flex-column-reverse flex-md-row ">
                    <Col md={8}>
                        <div className="efficencyTitle">{data.listtitle}</div>
                        {data.lists.map((x: any, i: any) => (
                            <ul key={i} className="efficencyUl">
                                <li>
                                    {x.list}
                                </li>
                            </ul>
                        ))}
                    </Col>
                    <Col md={4} className="border-radius padding-0 bgGreey col d-flex align-items-center justify-content-center">
                        <Image className="efficencyImg" src={data.image} />
                    </Col>
                </Row>
            ))}
        </Col>
    )
}
export default Cardlist