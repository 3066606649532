import { useState, useEffect } from 'react';
import {
	Button,
	Container,
	Image,
	Nav,
	Row,
	Col,
	Card,
	Form,
	Modal,
} from 'react-bootstrap';
import Standardform from './standardform';

function Servicecardstandard({ lists1 }: any) {
	const [data, setData] = useState([]);
	const [standardform, setStandard] = useState(Boolean);

	useEffect(() => {
		fetch('/mockdata/home.json')
			.then((res) => res.json())
			.then((res) => setData(res));
	}, []);

	const handleshowModal = ({ item }: any) => {
		setStandard(true);
	};

	const handleCloseModal = () => {
		setStandard(false);
	};

	return (
		<>
			<Col xs={12} sm={6} md={4} lg={4}>
				<div>
					{lists1.map((item: any, i: any) => (
						<ul className="list-group priceList">
							<li className="text-center bgBlue minHeight pl-0-tab">
								<div className="priceTitle">{item.title}</div>
								<div className="priceSubTitle">
									{item.subtitle}
								</div>
							</li>
							<li className=" priceliTitle">{item.listtitle}</li>
							<>
								{item.listinfo.map((x: any, i: any) => (
									<li key={i}>
										<i
											className="fa fa-long-arrow-right priceArrow"
											aria-hidden="true"
										></i>
										{x.list}
									</li>
								))}
							</>
							<div className="text-center priceList mt-0-tab mt-10">
								<div className="priceFooterTitle">
									{item.price}
								</div>
								<Button
									onClick={handleshowModal}
									type="submit"
									data-toggle="modal"
									className="btn custombtn txtwhite mgbottom50 mb-0-tab"
								>
									{item.button}
								</Button>
							</div>
						</ul>
					))}
				</div>
			</Col>

			<Modal
				centered
				show={standardform}
				onHide={handleCloseModal}
				className="modalRounded"
				size="lg"
			>
				<Modal.Body className="p-0">
					<Row>
						{data.map((item: any, i: any) => (
							<Col md={12} lg={7} className="modalRounded">
								{item.FreetrialFormImage.map(
									(x: any, i: any) => (
										<Image
											src={x.formimage}
											alt={x.formimage}
											className="modalImg"
										/>
									)
								)}
							</Col>
						))}
						<Col md={12} lg={5} className="mobilewidth">
							<Button
								variant="default"
								className="close modalClose"
								onClick={handleCloseModal}
							>
								&times;
							</Button>
							<Standardform onClose={handleCloseModal} />
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</>
	);
}
export default Servicecardstandard;
