import { yupResolver } from "@hookform/resolvers/yup";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import reloadImage from "../../assets/static/images/reload.png";
import { mailSendingAPIUrl } from "../../shared/constant";

function Channalpartnerform({ onClose }: any) {
    const [data, setData] = useState([]);
    const [invalid, showInvalid] = useState(false);
    const [captchaText, setCaptchaText] = useState("");
    const [answer, setAnswer] = useState("");
    const [popup, setPopup] = useState(false);
    const form = React.useRef() as React.MutableRefObject<HTMLFormElement>;

    useEffect(() => {
        getRandomString();
    }, []);

    useEffect(() => {
        fetch("/mockdata/contact.json")
            .then((res) => res.json())
            .then((res) => setData(res));
    }, []);

    function getRandomString() {
        const token = nanoid(5);
        setAnswer(token);
        console.log(token);
    }

    const handleClosepopup = () => {
        setPopup(false);
        onClose();
    };

    const schema = yup.object().shape({
        institute: yup
            .string()
            .trim()
            .required("Institute name is required")
            .matches(
                /^[aA-zZ\s.]+$/,
                "Only alphabets are allowed for this field "
            ),
        name: yup
            .string()
            .trim()
            .required("Name is required")
            .matches(
                /^[aA-zZ\s.]+$/,
                "Only alphabets are allowed for this field "
            ),
        contact: yup
            .string()
            .required("Contact Number is required")
            .min(10, "Must be exactly 10 digits")
            .max(10, "Must be exactly 10 digits")
            .typeError("Contact Number is required"),
        email: yup
            .string()
            .required("Email is required")
            .matches(
                /^\S+@\S+\.\S+$/,
                "Email should contain @ and . operators"
            ),
        city: yup.string().trim().required("City is required"),
        comment: yup.string().trim().required("Comment is required"),
        captcha: yup.string().required("Please enter the Captcha"),
    });

    const onSubmit = (data: any, e: any) => {
        console.log(data);
        e.preventDefault();
        if (data.captcha == answer) {
            data.subject = "Mentor ERP Channel Partner";
            fetch(mailSendingAPIUrl.EMAIL_SENDING_API_URL, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-type": "application/json;charset=utf-8" },
            }).then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error);
                }
            );
            setCaptchaText("");
            getRandomString();
            form?.current?.reset();
            showInvalid(false);
            setPopup(true);
        } else {
            showInvalid(true);
        }
    };

    const handleCaptachaRefresh = () => {
        getRandomString();
        setCaptchaText("");
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <>
            {data.map((item: any, i: any) => (
                <div key={i}>
                    {item.channel.map((val: any, i: any) => (
                        <Form
                            noValidate
                            onSubmit={handleSubmit(onSubmit)}
                            className="modalForm"
                            ref={form}
                        >
                            <div className="modalTitle">{val.title}</div>
                            <Form.Group>
                                <Form.Control
                                    {...register("institute")}
                                    autoComplete="off"
                                    placeholder="Name of Institute*"
                                    className="input"
                                ></Form.Control>
                                <div className="line-box">
                                    <div className="line"></div>
                                </div>
                                <span style={{ color: "red" }}>
                                    {errors?.institute?.message}
                                </span>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    {...register("name")}
                                    autoComplete="off"
                                    placeholder="Contact Person Name*"
                                    className="input"
                                ></Form.Control>
                                <div className="line-box">
                                    <div className="line"></div>
                                </div>
                                <span style={{ color: "red" }}>
                                    {errors?.name?.message}
                                </span>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    {...register("contact")}
                                    type="number"
                                    autoComplete="off"
                                    placeholder="Contact Number*"
                                    className="input"
                                ></Form.Control>
                                <div className="line-box">
                                    <div className="line"></div>
                                </div>
                                <span style={{ color: "red" }}>
                                    {errors?.contact?.message}
                                </span>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    {...register("email")}
                                    autoComplete="off"
                                    placeholder="Email Address*"
                                    className="input"
                                    type="email"
                                ></Form.Control>
                                <div className="line-box">
                                    <div className="line"></div>
                                </div>
                                <span style={{ color: "red" }}>
                                    {errors?.email?.message}
                                </span>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    {...register("city")}
                                    autoComplete="off"
                                    placeholder="City*"
                                    className="input"
                                ></Form.Control>
                                <div className="line-box">
                                    <div className="line"></div>
                                </div>
                                <span style={{ color: "red" }}>
                                    {errors?.city?.message}
                                </span>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Comment*:</Form.Label>
                                <Form.Control
                                    {...register("comment")}
                                    autoComplete="off"
                                    name="comment"
                                    className="form-control shadow-sm txtarea"
                                    as="textarea"
                                    rows={5}
                                ></Form.Control>
                                <div className="line-box">
                                    <div className="line"></div>
                                </div>
                                <span style={{ color: "red" }}>
                                    {errors?.comment?.message}
                                </span>
                            </Form.Group>
                            <Row>
                                <Col xs={12} md={5}>
                                    <Form.Group>
                                        <Form.Control
                                            {...register("captcha")}
                                            placeholder="Captcha*"
                                            className="input"
                                            autoComplete="off"
                                            value={captchaText}
                                            onChange={(e) =>
                                                setCaptchaText(e.target.value)
                                            }
                                        ></Form.Control>
                                        <div className="line-box">
                                            <div className="line"></div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xs={10} md={5}>
                                    <Form.Group>
                                        <Form.Control
                                            onCopy={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            placeholder="code"
                                            className="input"
                                            autoComplete="off"
                                            type="text"
                                            readOnly
                                            name="mainCaptcha"
                                            value={answer}
                                        ></Form.Control>
                                        <div className="line-box">
                                            <div className="line"></div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xs={2} md={2}>
                                    <Image
                                        src={reloadImage}
                                        className="reload"
                                        onClick={handleCaptachaRefresh}
                                    />
                                </Col>
                            </Row>
                            {!invalid && captchaText === "" && (
                                <span style={{ color: "red" }}>
                                    {errors?.captcha?.message}
                                </span>
                            )}
                            {invalid && (
                                <Row>
                                    <Form.Group>
                                        <span
                                            style={{
                                                color: "red",
                                                paddingLeft: "15px",
                                            }}
                                        >
                                            Invalid Captcha!...
                                        </span>
                                    </Form.Group>
                                </Row>
                            )}
                            <Col
                                md={12}
                                className="col-md-12 text-center mgtop-25"
                            >
                                <button
                                    type="submit"
                                    className="loadingBtn btn btn-lg custombtn btn-block txtwhite"
                                >
                                    Submit
                                </button>
                            </Col>
                        </Form>
                    ))}

                    <Modal
                        show={popup}
                        onHide={handleClosepopup}
                        className="alertBox"
                    >
                        <Modal.Header
                            style={{
                                backgroundColor: "#28a745",
                                color: "white",
                            }}
                        >
                            Thank you, your request has been sent.
                            <Button
                                variant="default"
                                className="close modalClose"
                                onClick={handleClosepopup}
                            >
                                &times;
                            </Button>
                        </Modal.Header>
                    </Modal>
                </div>
            ))}
        </>
    );
}
export default Channalpartnerform;
