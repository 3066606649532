import { Button, Container, Image, Nav, Row, Col, Card } from 'react-bootstrap';
import Indiaaddr from './indiaaddr';
import EmeaInternational from './emea';
import ApacInternational from './apac';
import Emailaddr from './email';
import Formdetails from './formdetail';
import { useState } from 'react';

function Contactinfo({ contactinfo }: any) {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	return (
		<div className="bgWhite" id="contactinfo">
			<Container className="padding-70 padding-bottom-0-mobile">
				<Container className="container_padding">
					<Row className="vertical-divider">
						<Col md={6} className="no-padding-mobile">
							<div className="addressPadding no-padding-mobile ">
								<Indiaaddr contactinfo={contactinfo} />
								<EmeaInternational contactinfo={contactinfo} />
								{/* <ApacInternational contactinfo={contactinfo} /> */}
								<Emailaddr contactinfo={contactinfo} />
							</div>
						</Col>
						{!(windowWidth < 765 && windowWidth > 392) && (
							<div className="col-0"></div>
						)}
						<Col md={5} className="no-padding-mobile ">
							<div className="addressPadding no-padding-mobile ">
								{/* <Formdetails contactinfo={contactinfo} /> */}
								<Formdetails />
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default Contactinfo;
