import { Button, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Automation({ automation }: any) {
    return (
        <div>
            <Col md={12} className="no-padding" id="bannerdiv">
                {automation.map((item: any, i: any) => (
                    <Row className="mx-0">
                        <Image
                            className="d-block w-100 fixed-height h-200-mobile"
                            src={item.image}
                        />
                        <div className="bannerdiv">
                            <h5>{item.title}</h5>
                            <Link to="/contact">
                                <Button
                                    className="btn txtwhite custombtn mobileMargin-0 mRight-25 ipadbtncss"
                                    onClick={() => window.scrollTo(0, 0)}
                                >
                                    {item.buttoncontent}
                                </Button>
                            </Link>
                        </div>
                    </Row>
                ))}
            </Col>
        </div>
    );
}
export default Automation;
