import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from "react-router-dom";
import ArrowPrev from '../../assets/static/images/Arrow_2.svg'
import ArrowNext from '../../assets/static/images/Arrow_1.svg';
import { truncate } from 'fs';

function Technology({ technology }: any) {
    return (
        <div className="bgGreey">
            {technology.map((item: any, i: any) => (
                <Container key={i} className="padding-70">
                    <h3 className="header">{item.head}</h3>
                    <Splide
                        options={{
                            perPage: 3,
                            arrows: false,
                            breakpoints: {
                                1199: {
                                    arrows: false,
                                    perPage: 3
                                },
                                992: {
                                    perPage: 3,
                                    arrows: false
                                },
                                767: {
                                    perPage: 1,
                                   
                                    arrows: true,
                                    prevArrow: false

                                }
                            },
                            rewind: true,
                            gap: '30px',
                            updateOnMove: true,
                            pagination: true,

                            // focus: "center",
                        }}


                    // renderControls={() => (
                    //     <div className="splide__arrows--tech">
                    //         <Button variant='default' className="splide__arrow splide__arrow--prev">
                    //             <Image src={ArrowPrev} />
                    //         </Button>
                    //         <Button variant='default' className="splide__arrow  splide__arrow--next">
                    //             <Image src={ArrowNext} />
                    //         </Button>
                    //     </div>
                    // )}
                    >
                        {item.images.map((data: any, i: any) => (
                            // <Col key={i} md={4} sm={4} xs={4}>

                            <SplideSlide >
                                <Row className="partnerSlider">
                                    <Col md={12}>
                                        <div className="item">
                                            <Image className="w-100 mobileBottom " src={data.image} />
                                        </div>
                                    </Col>
                                </Row>
                            </SplideSlide>

                        ))}

                    </Splide>
                </Container >
            ))
            }
        </div >

    )
}
export default Technology