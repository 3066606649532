import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';
import ArrowPrev from '../assets/static/images/Arrow_2.svg';
import ArrowNext from '../assets/static/images/Arrow_1.svg';
import { Slider } from 'antd';
import { NavLink } from 'react-router-dom';

function Erpkey({ erpkey }: any) {
	return (
		<div className="bgGreey">
			{erpkey.map((items: any, i: any) => (
				<Container className="paddingBottom40" key={i}>
					<h2 className="txtblue">{items.title}</h2>
					<Splide
						options={{
							type: 'loop',
							perMove: 1,
							perPage: 2,
							breakpoints: {
								1024: {
									perPage: 2,
									arrows: true,
								},
								980: {
									perPage: 1,
								},
								500: {
									perPage: 1,
									arrows: true,
								},
							},
							rewind: false,
							gap: '30px',
							updateOnMove: true,
							pagination: false,
							// focus: "center",
						}}
						renderControls={() => (
							<div className="splide__arrows">
								<Button
									variant="default"
									className="splide__arrow splide__arrow--prev splide__arrow--prev--button "
								>
									<Image
										src={ArrowPrev}
										className="splide__arrow--prev--1"
									/>
								</Button>
								<Button
									variant="default"
									className="splide__arrow splide__arrow--next splide__arrow--prev--button"
								>
									<Image
										src={ArrowNext}
										className="splide__arrow--prev--1"
									/>
								</Button>
							</div>
						)}
					>
						{items.slider.map((data: any, i: any) => (
							<SplideSlide key={i} className="efficiencyDiv">
								<Col md={12} className="paddingBottom40">
									<a
										href={data.imagelink}
										target="_blank"
										rel="noreferrer"
									>
										{' '}
										<Image
											className="sliderImg roundedtop"
											src={data.image}
											alt="Image"
										/>
									</a>
									<div className="driverSliderContent">
										<div className="sliderSubTitle">
											<a
												href={data.textlink}
												target="_blank"
												rel="noreferrer"
											>
												{' '}
												{data.text}
											</a>
										</div>
										{data.para.map((x: any, i: any) => (
											<p key={i}>{x}</p>
										))}

										<Nav.Link
											href={data.buttonlink}
											target="_blank"
											className="p-0 d-inline-block mt-5"
										>
											<Button className="sliderBtnMargin btn txtwhite custombtn">
												{data.buttontext}
											</Button>
										</Nav.Link>
									</div>
								</Col>
							</SplideSlide>
						))}
					</Splide>
				</Container>
			))}
		</div>
	);
}
export default Erpkey;
