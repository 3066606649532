import { Button, Container, Image, Nav, Row } from 'react-bootstrap';
import Cardlist from './cardlist';

function Driveefficiency({ efficiency }: any) {

    return (
        <div className="bgWhite">
            {efficiency.map((item: any, i: any) => (
                <Container key={i} className="container_padding">
                    <h2 className="txtblue paddingBottom30">{item.title}</h2>
                    <div className="efficency">
                        {item.text}
                    </div>
                    <Cardlist listbox={item.listbox1} />
                    <Cardlist listbox={item.listbox2} />
                    <Cardlist listbox={item.listbox3} />
                </Container>
            ))}
        </div>
    )
}
export default Driveefficiency