import { yupResolver } from "@hookform/resolvers/yup";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import reloadImage from "../../assets/static/images/reload.png";
import { mailSendingAPIUrl } from "../../shared/constant";

function Standardform({ onClose }: any) {
    const [invalid, showInvalid] = useState(false);
    const [popup, setPopup] = useState(false);
    const [captchaText, setCaptchaText] = useState("");
    const [answer, setAnswer] = useState("");
    const form = React.useRef() as React.MutableRefObject<HTMLFormElement>;

    useEffect(() => {
        getRandomString();
    }, []);

    const schema = yup.object().shape({
        institute: yup
            .string()
            .trim()
            .required("Institute name is required")
            .matches(
                /^[aA-zZ\s.]+$/,
                "Only alphabets are allowed for this field "
            ),
        name: yup
            .string()
            .trim()
            .required("Name is required")
            .matches(
                /^[aA-zZ\s.]+$/,
                "Only alphabets are allowed for this field "
            ),
        service: yup.string().required("Select One Service"),
        email: yup
            .string()
            .required("Email is required")
            .matches(
                /^\S+@\S+\.\S+$/,
                "Email should contain @ and . operators"
            ),
        contact: yup
            .string()
            .required("Contact Number is required")
            .min(10, "Must be exactly 10 digits")
            .max(10, "Must be exactly 10 digits")
            .typeError("Contact Number is required"),
        captcha: yup.string().required("Please enter the Captcha"),
    });

    const handleClose = () => {
        setPopup(false);
        onClose();
    };

    const onSubmit = (data: any, e: any) => {
        console.log(data);
        e.preventDefault();
        if (data.captcha == answer) {
            data.subject = "Discover Mentor ERP Now";
            fetch(mailSendingAPIUrl.EMAIL_SENDING_API_URL, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-type": "application/json;charset=utf-8" },
            }).then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error);
                }
            );
            setCaptchaText("");
            getRandomString();
            form?.current?.reset();
            showInvalid(false);
            setPopup(true);
        } else {
            showInvalid(true);
        }
    };

    function getRandomString() {
        const token = nanoid(5);
        setAnswer(token);
        console.log(token);
    }

    const handleCaptachaRefresh = () => {
        getRandomString();
        setCaptchaText("");
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <div>
            <Form
                onSubmit={handleSubmit(onSubmit)}
                className="modalForm"
                id="contactForm"
                ref={form}
            >
                <div className="header text-left modalTitle">
                    Discover Mentor ERP Now!
                </div>

                <Form.Group>
                    <Form.Control
                        {...register("institute")}
                        placeholder="Name of Institute*"
                        className="input"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.institute?.message}
                    </span>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        {...register("name")}
                        placeholder="Contact Person Name*"
                        type="text"
                        className="input"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.name?.message}
                    </span>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        {...register("contact")}
                        type="number"
                        placeholder="Contact Number*"
                        className="input"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.contact?.message}
                    </span>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        {...register("email")}
                        type="email"
                        placeholder="Email ID*"
                        className="input"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.email?.message}
                    </span>
                </Form.Group>
                <select
                    {...register("service")}
                    name="service"
                    id="service"
                    placeholder="Select Service*"
                    className="input"
                >
                    <option value="">Select Service *</option>
                    <option value="Standard" selected>
                        Standard
                    </option>
                    <option value="Professional">Professional</option>
                    <option value="Premium">Premium</option>
                </select>
                <div className="line-box">
                    <div className="line"></div>
                </div>
                <span style={{ color: "red" }}>{errors?.service?.message}</span>

                <Row>
                    <Col xs={10} md={5}>
                        <Form.Group>
                            <Form.Control
                                {...register("captcha")}
                                placeholder="Captcha*"
                                className="input"
                                autoComplete="off"
                                value={captchaText}
                                onChange={(e) => setCaptchaText(e.target.value)}
                            ></Form.Control>
                            <div className="line-box">
                                <div className="line"></div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={10} md={5}>
                        <Form.Group>
                            <Form.Control
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                placeholder="code"
                                className="input"
                                autoComplete="off"
                                type="text"
                                readOnly
                                name="mainCaptcha"
                                value={answer}
                            ></Form.Control>
                            <div className="line-box">
                                <div className="line"></div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={2} md={2}>
                        <Image
                            src={reloadImage}
                            className="reload"
                            onClick={handleCaptachaRefresh}
                        />
                    </Col>
                </Row>
                {!invalid && captchaText === "" && (
                    <span style={{ color: "red" }}>
                        {errors?.captcha?.message}
                    </span>
                )}
                {invalid && (
                    <Row>
                        <Form.Group>
                            <span style={{ color: "red", paddingLeft: "15px" }}>
                                Invalid Captcha!...
                            </span>
                        </Form.Group>
                    </Row>
                )}

                <Col md={12} className="col-md-12 text-center mgtop-25">
                    <button
                        type="submit"
                        className="loadingBtn btn btn-lg custombtn btn-block txtwhite"
                    >
                        Submit
                    </button>
                </Col>
            </Form>
            <Modal show={popup} onHide={handleClose} className="alertBox">
                <Modal.Header
                    style={{ backgroundColor: "#28a745", color: "white" }}
                >
                    Thank you, your request has been sent.
                    <Button
                        variant="default"
                        className="close modalClose"
                        onClick={handleClose}
                    >
                        &times;
                    </Button>
                </Modal.Header>
            </Modal>
        </div>
    );
}
export default Standardform;
