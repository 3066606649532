import { lazy, Suspense } from "react";
import { ProgressBar } from "react-bootstrap";
import MentorErp from "./components/home/mentorerp";
import Home from "./components/home/home";
import Product from "./components/product/product";
import Service from "./components/service/service";
import Servicetwo from "./components/service/servicepagetwo";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Privacy from "./components/privacy/privacy";

const routes = [
    {
        path: "*",
        element: <Home />,
    },
    {
        path: "/home",
        element: <Home />,
    },
    {
        path: "/product",
        element: <Product />,
    },
    {
        path: "/development",
        element: <Service />,
    },
    {
        path: "/developmenttwo",
        element: <Servicetwo />,
    },
    {
        path: "/about",
        element: <About />,
    },
    {
        path: "/contact",
        element: <Contact />,
    },
    {
        path: "/privacy",
        element: <Privacy />,
    },
];

export default routes;
