import { Button, Container, Image, Nav, Row, Col, Card } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

function Emailaddr({ contactinfo }: any) {
    return (
        <div>
            {contactinfo.map((mainitems: any, i: any) => (
                <>
                    {mainitems.addrinternational.map((items: any, i: any) => (
                        <>
                            {items.email.map((item: any, i: any) => (
                                <>
                                    <h2 key={i} className="header addressTitle text-left">
                                        {item.subhead}
                                    </h2>
                                    <div className="address no-padding-mobile ">
                                        <ul className="emaillinks no-padding-mobile ">

                                            <li>
                                                {item.textvalue.map((x: any, i: any) => (
                                                    <Nav.Link href={x.mailto} className="no-padding-mobile ">
                                                        <Image className="iconimg" src={x.image} />
                                                        <span style={{ color: "#6A6A6A" }}>{x.text}</span>
                                                    </Nav.Link>
                                                ))}
                                            </li>

                                        </ul>
                                    </div>
                                </>
                            ))}
                        </>
                    ))}
                </>
            ))}
        </div>


    )
}
export default Emailaddr