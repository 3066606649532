import { Button, Container, Image, Nav, Row, Col } from 'react-bootstrap';

function Mentorbottom({ mentorbottom }: any) {
	return (
		<div className="homeBottom">
			<Container className="container-fluid">
				<Row>
					{mentorbottom.map((x: any, i: any) => (
						<Col
							xs={12}
							md={8}
							lg={9}
							className="pb-18-tablet pb-18-mobile"
						>
							<h2 className="footerh2 fs-25-mobile fs-50">
								{x.title}
							</h2>
						</Col>
					))}
				</Row>
			</Container>
		</div>
	);
}
export default Mentorbottom;
