import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, Routes, useLocation, useRoutes } from "react-router-dom";
import routes from "./router";
import Header from "./layout/master/header";
import Footer from "./layout/master/footer";
import { Button, Image } from "react-bootstrap";
import scrolltop from "./assets/static/images/Scroll_Top_Arrow.svg";
import { useEffect, useLayoutEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

function App() {
    const pages = useRoutes(routes);
    const [bodyScroll, setBodyScroll] = useState(false);
    const location = useLocation();

    useLayoutEffect(() => {
        window.addEventListener("scroll", () => {
            setBodyScroll(window.scrollY > 100);
        });
    }, []);

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className="App">
            <Header />
            <TransitionGroup>
                <CSSTransition key={location?.key} timeout={300}>
                    <Routes location={location}>
                        {routes?.map((route: any, i: any) => (
                            <Route
                                path={route?.path}
                                element={route?.element}
                                caseSensitive
                            />
                        ))}
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
            <Footer />
            <Button
                variant="default"
                onClick={scrollTop}
                className="scrollTop"
                id="scrollTop"
                title="Go to top"
                style={{ display: bodyScroll ? "block" : "none" }}
            >
                <Image src={scrolltop} alt={scrolltop} />
            </Button>
        </div>
    );
}

export default App;
