import { useEffect, useState } from "react";
import { SliderComponent } from "../../helpers/carousel";
import Keybenefits from "./keybenefits";
import MentorErp from "../home/mentorerp";
import Driveefficiency from "./driveefficiency";
import Erpkey from "../../helpers/keycarousel";
import Servicemodel from "./servicemodel";
import Mentorbottom from "./mentorbottom";

function Product() {
    const [data, setData] = useState([]);
    const [datahome, setDatahome] = useState([]);

    useEffect(() => {
        fetch("/mockdata/mentorerp.json")
            .then((res) => res.json())
            .then((res) => setData(res));
    }, []);

    useEffect(() => {
        fetch("/mockdata/home.json")
            .then((res) => res.json())
            .then((res) => setDatahome(res));
    }, []);

    return (
        <>
            {data.map((item: any, i: any) => (
                <section key={i}>
                    <SliderComponent Slider={item.Slider} />
                    <Keybenefits keybene={item.keybene} />
                    {datahome.map((x: any) => (
                        <MentorErp mentorerp={x.mentorerp} />
                    ))}
                    <Driveefficiency efficiency={item.efficiency} />

                    {datahome.map((x: any) => (
                        <Erpkey erpkey={x.erpkey} />
                    ))}
                    <Servicemodel service={item.service} />
                    <Mentorbottom mentorbottom={item.mentorbottom} />
                </section>
            ))}
        </>
    );
}

export default Product;
