import { yupResolver } from "@hookform/resolvers/yup";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import reloadImage from "../../assets/static/images/reload.png";
import { mailSendingAPIUrl } from "../../shared/constant";

const Demoform = ({ onClose }: any) => {
    const [invalid, showInvalid] = useState(false);
    const [captchaText, setCaptchaText] = useState("");
    const [answer, setAnswer] = useState("");
    const [inputType, setInputType] = useState("text");
    const [popup, setPopup] = useState(false);
    const form = React.useRef<HTMLFormElement | null>(null);

    useEffect(() => {
        getRandomString();
    }, []);

    function getRandomString() {
        const token = nanoid(5);
        setAnswer(token);
        console.log(token);
    }

    const schema = yup.object().shape({
        institute: yup
            .string()
            .trim()
            .required("Institute name is required")
            .matches(
                /^[aA-zZ\s.]+$/,
                "Only alphabets are allowed for this field "
            ),
        name: yup
            .string()
            .trim()
            .required("Name is required")
            .matches(
                /^[aA-zZ\s.]+$/,
                "Only alphabets are allowed for this field "
            ),
        contact: yup
            .string()
            .required("Contact Number is required")
            .min(10, "Please enter valid Contact Number")
            .max(10, "Please enter valid Contact Number")
            .typeError("Contact Number is required"),
        email: yup
            .string()
            .required("Email is required")
            .matches(
                /^\S+@\S+\.\S+$/,
                "Email should contain @ and . operators"
            ),
        date: yup
            .date()
            .required("Please choose a date")
            .min(new Date(), "Please choose a future date")
            .typeError("Please choose a date"),
        captcha: yup.string().required("Please enter the Captcha"),
    });

    const handleClose = () => {
        setPopup(false);
        onClose();
    };

    const onSubmit = (data: any, e: any) => {
        console.log("e", e);
        e.preventDefault();
        if (data.captcha === answer) {
            data.subject = "Schedule a Demo";
            fetch(mailSendingAPIUrl.EMAIL_SENDING_API_URL, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-type": "application/json;charset=utf-8" },
            }).then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error);
                }
            );
            getRandomString();
            setCaptchaText("");
            form?.current?.reset();
            showInvalid(false);
            setPopup(true);
        } else {
            showInvalid(true);
            setPopup(false);
        }
    };

    const handleCaptachaRefresh = () => {
        getRandomString();
        setCaptchaText("");
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <div>
            <Form
                onSubmit={handleSubmit(onSubmit)}
                className="modalForm"
                ref={form}
            >
                <div className="modalTitle">Schedule a Demo</div>
                <Form.Group>
                    <Form.Control
                        {...register("institute")}
                        placeholder="Name of Institute*"
                        className="input"
                        type="text"
                        // name="institute"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.institute?.message}
                    </span>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        {...register("name")}
                        placeholder="Contact Person Name*"
                        className="input"
                        autoComplete="off"
                        type="text"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.name?.message}
                    </span>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        {...register("contact")}
                        placeholder="Contact Number*"
                        type="number"
                        className="input number"
                        autoComplete="off"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.contact?.message}
                    </span>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        {...register("email")}
                        placeholder="Email ID*"
                        className="input"
                        autoComplete="off"
                        type="email"
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.email?.message}
                    </span>
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        {...register("date")}
                        // placeholder="Preferred Date*"
                        type={inputType}
                        name="date"
                        className="input"
                        onFocus={() => setInputType("date")}
                        onBlur={() => setInputType("text")}
                        placeholder={
                            inputType === "text" ? "Select a date*" : ""
                        }
                        min={new Date().toISOString().slice(0, 10)}
                    ></Form.Control>
                    <div className="line-box">
                        <div className="line"></div>
                    </div>
                    <span style={{ color: "red" }}>
                        {errors?.date?.message}
                    </span>
                </Form.Group>
                <Row>
                    <Col xs={12} md={5}>
                        <Form.Group>
                            <Form.Control
                                {...register("captcha")}
                                placeholder="Captcha*"
                                className="input"
                                autoComplete="off"
                                value={captchaText}
                                onChange={(e) => setCaptchaText(e.target.value)}
                            ></Form.Control>
                            <div className="line-box">
                                <div className="line"></div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={10} md={5}>
                        <Form.Group>
                            <Form.Control
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                placeholder="code"
                                className="input"
                                autoComplete="off"
                                type="text"
                                readOnly
                                name="mainCaptcha"
                                value={answer}
                            ></Form.Control>
                            <div className="line-box">
                                <div className="line"></div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={2} md={2}>
                        <Image
                            src={reloadImage}
                            className="reload"
                            onClick={handleCaptachaRefresh}
                        />
                    </Col>
                </Row>
                {!invalid && captchaText === "" && (
                    <span style={{ color: "red" }}>
                        {errors?.captcha?.message}
                    </span>
                )}
                {invalid && (
                    <Row>
                        <Form.Group>
                            <span style={{ color: "red", paddingLeft: "15px" }}>
                                Invalid Captcha!...
                            </span>
                        </Form.Group>
                    </Row>
                )}
                <Row>
                    <Col xs={12} md={12} className="mgtop-25">
                        <Button
                            type="submit"
                            variant="default"
                            className="loadingBtn btn btn-lg custombtn btn-block txtwhite"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Modal show={popup} onHide={handleClose} className="alertBox">
                <Modal.Header
                    style={{ backgroundColor: "#28a745", color: "white" }}
                >
                    Thank you, your request has been sent.
                    <Button
                        variant="default"
                        className="close modalClose"
                        onClick={handleClose}
                    >
                        &times;
                    </Button>
                </Modal.Header>
            </Modal>
        </div>
    );
};
export default Demoform;
