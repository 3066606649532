import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SubPageBanner2 } from "./bannerservice";
import Devops from "./devops";
import Primaryfocus from "./primary focus";
import Development from "../home/development";
import TDDmethod from "./TDD";
import Automation from "./automation";
import Testingservice from "./testservice";
import Testautomation from "./testautomation";
import Testing from "../home/testing";
import { useAppContext } from "../context";

function Servicetwo() {
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const { clickedMenuState } = useAppContext();
    const [clickedMenuValue, setClickedMenuValue] = clickedMenuState;

    useEffect(() => {
        fetch("/mockdata/service.json")
            .then((res) => res.json())
            .then((res) => setData(res));
        setClickedMenuValue("Services");
    }, []);

    useEffect(() => {
        fetch("/mockdata/home.json")
            .then((res) => res.json())
            .then((res) => setData1(res));
    }, []);

    return (
        <>
            {data.map((item: any, i: any) => (
                <section key={i}>
                    <div>
                        <Automation automation={item.automation} />
                        <Testingservice testservice={item.testservice} />
                        <Testautomation testautomation={item.testautomation} />
                        {data1.map((item: any, i: any) => (
                            <Testing testing={item.testing} />
                        ))}
                        <TDDmethod tddmethod={item.tddmethodtwo} />
                    </div>
                </section>
            ))}
        </>
    );
}

export default Servicetwo;
