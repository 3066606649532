import { useEffect, useState } from "react";
import { SubPageBanner } from "../../helpers/banner";
import Coreteam from "./coreteam";
import Ourculture from "./culture";
import Empowerment from "./empowerment";
import Mission from "./mission";

function About() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch("/mockdata/about.json")
            .then((res) => res.json())
            .then((res) => setData(res));
    }, []);

    return (
        <>
            {data.map((item: any, i: any) => (
                <section key={i}>
                    <SubPageBanner Slider={item.subBanner} />
                    <Empowerment empower={item.empower} />
                    <Mission missiontext={item.missiontext} />
                    <Ourculture culture={item.culture} />
                    <Coreteam core={item.core} />
                </section>
            ))}
        </>
    );
}

export default About;
