import { useState, useEffect } from 'react';
import {
	Button,
	Container,
	Image,
	Nav,
	Row,
	Col,
	Card,
	Form,
	Modal,
} from 'react-bootstrap';
import Channalpartnerform from './channalpartnerform';

function Channelpartner({ channel }: any) {
	const [data, setData] = useState([]);
	useEffect(() => {
		fetch('/mockdata/contact.json')
			.then((res) => res.json())
			.then((res) => setData(res));
	}, []);

	const [standardform, setStandard] = useState(Boolean);
	var arr: any[];

	const handleshowModal = ({ item }: any) => {
		setStandard(true);
	};

	const handleCloseModal = () => {
		setStandard(false);
	};

	return (
		<div className="bgWhite">
			{channel.map((item: any, i: any) => (
				<Container className="container_padding">
					<h2 key={i} className="txtblue paddingBottom30">
						{item.title}
					</h2>
					<Row className="row">
						<Col md={12}>
							<div key={i} className="erpBanner h-357-mobile">
								<h5>{item.subtitle}</h5>
								<Button
									onMouseDown={handleshowModal}
									className="btn txtwhite custombtn mobileMargin-0 mRight-25"
									data-toggle="modal"
								>
									{item.buttontext}
								</Button>
							</div>
							<Image
								className="w-100 paddingBottom50 contctBannerHeight contctBannerHeight-tablet"
								src={item.image}
							/>
						</Col>
					</Row>
				</Container>
			))}

			<Modal
				centered
				show={standardform}
				onHide={handleCloseModal}
				className="modalRounded"
				size="lg"
			>
				<Modal.Body className="p-0">
					<Row>
						{data.map((item: any, i: any) => (
							<Col md={12} lg={7} className="modalRounded">
								{item.channel.map((x: any, i: any) => (
									<Image
										src={x.formimage}
										alt={x.formimage}
										className="modalImg"
									/>
								))}
							</Col>
						))}
						<Col md={12} lg={5} className="mobilewidth">
							<Button
								variant="default"
								className="close modalClose"
								onClick={handleCloseModal}
							>
								&times;
							</Button>
							<Channalpartnerform onClose={handleCloseModal} />
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</div>
	);
}
export default Channelpartner;
